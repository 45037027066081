import React from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import BarChart from '@material-ui/icons/BarChart';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { trade_headers, used_language } from '../../langues';


const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    card: {
        width: 300,
    },
    cardHeader: {
        paddingBottom: 0
    },
    cardContent: {
        paddingTop:8,
        paddingBottom:0
    },
    alignCenter: {
        textAlign: 'center'
    },
    cardItem: {
        minWidth: 40,
        textAlign: 'center'
    },
    cardItemTitle: {
        textAlign: 'center',
        fontSize: 12,
        fontVariant: 'small-caps',
        marginLeft:5        
    },
    marginLeft: {
        marginLeft:15
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4CAF50',
            main: '#4CAF50',
            dark: '#388E3C',
            contrastText: '#fff',
          },
        sec: red
    },
    typography: {
        useNextVariants: true
    }
});


function IndicatorPresentationnal(props) {
    const { market, classes, indicators } = props;  

    return (
        <div>
            <MuiThemeProvider theme={theme}>   
                <Card className={classes.card} >  
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Avatar aria-label={market.long} style={{backgroundColor: market.color}}>
                                {market.sigle}
                        </Avatar>
                    }
                    action={
                        <IconButton style={{color: 'black'}}>
                            <BarChart />
                        </IconButton>
                    }
                    title="FDAX"
                    />      
                    <CardContent className={classes.cardContent}>
                    <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="stretch"
                    spacing={16}
                    >
                        <Grid item>
                            <Grid container direction="row" justify="flex-start"
                        alignItems="center">
                                    <Grid item className={classes.cardItemTitle}>
                                        {trade_headers[used_language][3]}
                                    </Grid>
                                    <Grid item className={[classes.cardItemTitle, classes.marginLeft].join(" ")}>
                                        {trade_headers[used_language][2]}
                                    </Grid>
                                    <Grid item className={[classes.cardItemTitle, classes.marginLeft].join(" ")}>
                                        {trade_headers[used_language][4]}     
                                    </Grid>                       
                                </Grid>
                            
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" justify="space-between"
                        alignItems="center">
                                    <Grid item className={classes.cardItem}>
                                        {indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].strenghtBuy > 0 ? indicators.filter(element => element.code === "FDAX")[0].strenghtBuy : "-"}
                                    </Grid>
                                    <Grid item className={classes.cardItem}>
                                    {indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].buy > 0 ? indicators.filter(element => element.code === "FDAX")[0].buy + '%' : "-"}

                                    </Grid>
                                    <Grid item className={classes.cardItem}>
                                        {indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].balanceBuy > 0 ? indicators.filter(element => element.code === "FDAX")[0].balanceBuy : "-"}
                                    </Grid>
                                    <Grid item className={classes.cardItem}>
                                        <Button variant="contained" color="primary">
                                            ACHAT
                                        </Button>      
                                    </Grid>                              
                                </Grid>
                            
                        </Grid>

                        <Grid item >
                            <Grid container direction="row" justify="space-between"
                    alignItems="center">
                                <Grid item className={classes.cardItem}>
                                    {indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].strenghtSell > 0 ? indicators.filter(element => element.code === "FDAX")[0].strenghtSell : "-"}
                                </Grid>
                                <Grid item className={classes.cardItem}>
                                    {indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].sell > 0 ? indicators.filter(element => element.code === "FDAX")[0].sell + '%' : "-"}
                                </Grid>
                                <Grid item className={classes.cardItem}>
                                {indicators.find(element => element.code === "FDAX") && indicators.filter(element => element.code === "FDAX")[0].balanceSell > 0 ? indicators.filter(element => element.code === "FDAX")[0].balanceSell : "-"}
                                </Grid>
                                <Grid item className={classes.cardItem}>
                                <Button variant="contained" color="secondary">
                                    VENTE
                                </Button>
                                </Grid>  
                            </Grid>
                        </Grid>
                        
                    </Grid>


                    </CardContent>
                </Card>
                </MuiThemeProvider>
        </div>
    );
}

function mapStateToProps({ indicators }) {
    return { indicators };
};

export default connect(mapStateToProps)(withStyles(styles)(IndicatorPresentationnal));
