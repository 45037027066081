import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import LoginContainer from '../../components/login/loginContainer';

const styles = {
  card: {
    width: 407,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 100
  },
  media: {
    height: 116
  }
};

class Login extends Component {
  
  render() {
    return (
      <div>
        <LoginContainer />
      </div>
    );
  }
}

export default withStyles(styles)(Login);
