import { loginConstants, websocketConstants } from '../../constants';

function loginReducer (state = {}, action) {
    switch(action.type) {
        case loginConstants.LOGIN_REQUEST:
            return {
                login_success: action.payload.login_success, 
                ...state
            };

        case loginConstants.LOGOUT:
            state.login_success = false;
            //state.socket.close();
            return state;
        
        case websocketConstants.AJOUT_SOCKET:
            return {
                socket: action.payload,
                ...state
            };

        default:
            return state
    }
}

export default loginReducer;