import { dev_trade, notificationConstants } from '../../constants';

const notif_from_trade = (type, data) => {

    const date = type === notificationConstants.CLOSED_TRADE ? data.date2 : data.date1;
    const prix = type === notificationConstants.CLOSED_TRADE ? data.value2 : data.value1;
    return {
        type: type,
        market: data[dev_trade.MARKET],
        price: prix,
        date: date,
        seen: false
    }
}

function notificationReducer (state = [], action) {
    switch(action.type) {
        case notificationConstants.NEW_TRADE:
            return [notif_from_trade(action.type, action.payload), ...state];

        case notificationConstants.CLOSED_TRADE:
            return [notif_from_trade(action.type, action.payload), ...state];
            
        case notificationConstants.MAKE_ALL_SEEN:
            return state.map(x => {x.seen = true; return x})
        
        default:
            return state
    }
}

export default notificationReducer;
