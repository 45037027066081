import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Paper from '@material-ui/core/Paper';
import { getAllAccounts } from '../accounts/accountAction';
import { getStatsPersonToday, getStatsPersonWeek, getStatsPersonMonth, getStatsIAToday, getStatsIAWeek, getStatsIAMonth } from './statAction'
import Grid from '@material-ui/core/Grid';
import OneStat from './oneStat';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import grey from '@material-ui/core/colors/grey';
import { used_language, statTabs } from '../../langues';
import { dev_stats_fields } from '../../constants';

const styles = theme => ({
    root: {
      flexGrow: 1
    },
    control: {
      padding: theme.spacing.unit * 2
    },
    title: {
       fontSize: 20,
       fontWeigth: 'bold'
    },
    demo: {
        margin:20
    },
    avatar: {
        backgroundColor: grey[300],
        width: 50,
        height: 50
    },
    card: {
        backgroundColor: grey[50]
    },
    minMax: {
        'min-width': 360,
        'max-width': 360,
        margin: 20
    },
    paper: {
        marginTop: 30
    },
    tabs: {
        width: '100%'
    },
    img: {
        textAlign: 'center',
        margin: '0px auto'
    }
});

class StatContainer extends Component {
    state = {
        tab_number: 0,
      };
    
    componentDidMount() {
        // Both this.stats.state.ia and this.props.stats.person are an object with fields: today, week, month
        this.props.getAllAccounts();
        
        this.props.getStatsPersonToday();
        this.props.getStatsPersonWeek();
        this.props.getStatsPersonMonth();

        this.props.getStatsIAToday();
        this.props.getStatsIAWeek();
        this.props.getStatsIAMonth();
    }

    handleChange = (event, tab_number) => {
        this.setState({ tab_number });
    };

    render() {
        const { classes } = this.props
        const { tab_number } = this.state;
        this.person_stats = [
            () => this.props.getStatsPersonToday(),
            () => this.props.getStatsPersonWeek(),
            () => this.props.getStatsPersonMonth()
        ];
        this.ia_stats = [
            () => this.props.getStatsIAToday(),
            () => this.props.getStatsIAWeek(),
            () => this.props.getStatsIAMonth()
        ]
        
        return (
            <div>
                <Paper className={classes.paper} >
                    <Grid container className={classes.root} spacing={16}>
                        <Grid container className={classes.demo} justify="flex-start" alignItems="flex-start" spacing={40}>
                            <Grid container item justify="center" className={classes.tabs}>
                                <Tabs
                                value={tab_number}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                >
                                    {statTabs[used_language].map((x, i) => <Tab label={x} key={i} value={i} />)}
                                </Tabs>
                            </Grid>
                            {dev_stats_fields.map((f, i) => {
                                const to_pass_person = this.props.stats !== undefined ? this.props.stats.person !== undefined ? this.props.stats.person[f] : undefined : undefined;
                                return (tab_number === i && 
                                <Grid container item xs={12} key={i} direction="row">
                                    <Grid item xs={12}>
                                        <OneStat stats={to_pass_person} update={this.person_stats[i]} />
                                    </Grid>
                                </Grid>)
                                })
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

function mapStateToProps({ accounts, stats }) {
    return { accounts, stats };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllAccounts, getStatsPersonToday, getStatsPersonWeek, getStatsPersonMonth, getStatsIAToday, getStatsIAWeek, getStatsIAMonth }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatContainer));