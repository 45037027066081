import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import grey from '@material-ui/core/colors/grey';
import brown from '@material-ui/core/colors/brown';

const styles = theme => ({
    root: {
      flexGrow: 1
    },
    control: {
        padding: theme.spacing.unit * 2
      },
    title: {
        fontSize: 20,
        fontWeigth: 'bold'
    },
    avatar: {
        backgroundColor: grey[300],
        width: 50,
        height: 50
    },
    icon: {
        color: brown[400],
        width: 40,
        height: 40
    },
    icon_add: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3
    },
    minMax: {
        'min-width': 400,
        margin:20
    },
    buttonAdd: {
        margin: 20
    }
});


AccountPresentationnal.propTypes = {
    accounts: PropTypes.array.isRequired,
    handleAddAccount: PropTypes.func.isRequired
}

function AccountPresentationnal(props) {
    const { classes, accounts, handleAddAccount } = props; 
    return (
        <Grid container className={classes.root} spacing={16}>
            <Grid item xs={12}>
                <Grid container className={classes.demo} justify="flex-start" alignItems="flex-start" spacing={40}>
                    {accounts.map(account => (
                    <Grid key={account.id} item xs={3} className={classes.minMax}>
                        <Card style={{backgroundColor: "#FFF"}}>
                            <CardHeader
                            classes={{
                                title: classes.title,
                                }}
                            avatar={
                                <Avatar className={classes.avatar}>
                                    <Person className={classes.icon} />
                                </Avatar>
                            }
                            title={account.name}
                            subheader={account.func}
                        />
                        </Card>     
                    </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container justify="flex-end">
                <Grid>
                    <Button variant="fab" color='primary' className={classes.buttonAdd} onClick={() => handleAddAccount()} >
                        <AddIcon />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(AccountPresentationnal);