import history from '../../routes/history';
import { getNewTrade, updateOneTrade, closeTrade } from '../trades/tradeAction';
import { updateMarketCard } from '../trades/indicatorAction';
import { addNotification } from '../notifications/notificationAction';
import { loginConstants, login_route, websocketConstants, notificationConstants } from '../../constants';

export const login = (username, pass, callback_success, callback_error) => {
    
    const login_success = (username === 'robankhood' && pass === 'robank2018');
        
    const login_request = {login_success: login_success};

    login_success ? callback_success() : callback_error()
    
    return {
        type: loginConstants.LOGIN_REQUEST,
        payload: login_request
    };
}

export const logout = () => {
    history.push(login_route);
    return {
        type: loginConstants.LOGOUT
    };
}

export function addSocketToStore(socket) {
    return {
        type: websocketConstants.AJOUT_SOCKET,
        payload: socket
    }
}

export function connectWebSocket(url) {
    // Use of redux-thunk to return a function that dispatches the right actions.
    return (dispatch) => {
    const socket = new WebSocket(url)
    socket.onopen = () => console.log('Websocket connected with the server');
    socket.onmessage = (message) => {
        const data = JSON.parse(message.data);

        switch(data.action) {
            case websocketConstants.TRADE_OPENED:
                dispatch(getNewTrade(data));
                dispatch(addNotification(notificationConstants.NEW_TRADE, data));
            break;

            case websocketConstants.PRICE_UPDATE:
                dispatch(updateOneTrade(data));
            break;

            case websocketConstants.ACCURATE_UPDATE:
                dispatch(updateMarketCard(data));
            break;

            case websocketConstants.STRENGHT_UPDATE:
                dispatch(updateMarketCard(data));
            break;

            case websocketConstants.BALANCE_UPDATE:
                dispatch(updateMarketCard(data));
            break;

            case websocketConstants.TRADE_CLOSED:
                dispatch(closeTrade(data));
                dispatch(addNotification(notificationConstants.CLOSED_TRADE, data));
            break;
            
            default: 
                break;
        }
    }
    socket.onclose = (e) => {console.log('Websocket closed ... '); console.log(e); console.log('Reconnecting the websocket'); dispatch(connectWebSocket(url));}
    socket.onerror = (e) => {console.log('Error with the Websocket ...'); console.log(e);}
    
    dispatch(addSocketToStore(socket));
    
    }
}
