import Login from '../views/login/login';
import { login_route } from '../constants';

export default {
    Login: {
        component: Login,
        path: login_route
    }
};

