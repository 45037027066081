import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import round from 'round-to';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Done from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { getAllTradesFixed, getAllCurrentTrades, disableOneTrade, tradeAddProfit } from './tradeAction';
import TradePresentationnal from './tradePresentationnal';
import IndicatorPresentationnal from './indicatorPresentationnal';
import { trade_headers, used_language, trade_side_label } from '../../langues';
import { websocketConstants, trade_ticks_and_prices } from '../../constants';
import { dev_trade, dev_trade_fields, dev_trade_liquidate, dev_sort } from '../../constants';
import { markets } from '../markets/marketAction';
import _ from 'lodash';

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    }
});

const raw_headers = trade_headers[used_language];

const headers = dev_trade_fields.map( function (a, i) { 
    return {id: a, label: raw_headers[i]};
});

class TradeContainer extends Component {
    // this.props.stats.trades contains 3 fields:
    // fixed : the closed trades
    // closing : the trades we are trying to close (most certainly, there is more than 1 lot and not all of them are closed)
    // open : the trades that are not closed. You can see them change in real time, and they are updated amongst reception of PriceUpdate from the server
    
    en_cours = (obj) => {
        return !obj[dev_trade.CLOSE_DATE];
    }
    
    liquidate = (socket, code, side, qty) => {
        if(socket !== undefined) {
            const toSend = {[dev_trade_liquidate.ACTION]: websocketConstants.LIQUIDATE, [dev_trade_liquidate.MARKET]: code, [dev_trade_liquidate.SIDE]: side, [dev_trade_liquidate.QUANTITY_1]: qty, [dev_trade_liquidate.USER_ID]: 1};
            socket.send(JSON.stringify(toSend));
            this.props.disableOneTrade(code);
        }
      }
    
    // Permet de designer les champs du composant table
    // Accept DOM pour styliser
    // dev_trade.TYPE pour choisir le bon champ
    // NB : ne pas oublier { }
    label_to_print = (obj, head, undef_default, socket) => {
        if(head.id === dev_trade.MARKET) {
            const market = markets.find(x => x.short === obj[dev_trade.MARKET])
            return <Grid container justify='center'>
                        <Avatar aria-label={market.long} style={{backgroundColor: market.color}}>
                            {market.sigle}
                        </Avatar>
                    </Grid>
        }
        if (head.id === dev_trade.SIDE) {
            return trade_side_label[used_language][obj[dev_trade.SIDE]]
        }
        if (head.id === dev_trade.PROFIT) {
            return obj[head.id] !== undefined ? 
            new Intl.NumberFormat('de-DE', { style: 'currency', currency: trade_ticks_and_prices[obj[dev_trade.MARKET]].currency }).format(obj[head.id]) : undef_default
        }
        if (head.id === dev_trade.ACCURACY || head.id === dev_trade.BALANCE) {
            return obj[head.id] !== "undefined" ? round(parseFloat(obj[head.id].replace(',', '.')) * 100, 2) + ' %' : undef_default
        }
        if (head.id === dev_trade.STRENGTH) {
            return obj[head.id] !== "undefined" ? round(parseFloat(obj[head.id].replace(',', '.')), 1) : undef_default
        }
        if (head.id === dev_trade.STATUS) {
            return this.en_cours(obj) ? <MoreHoriz /> : <Done />;
          }
        if (head.id === dev_trade.LIQUIDATE && this.en_cours(obj)) {
            return <Button 
                        variant="contained" 
                        color="secondary" 
                        className={this.props.classes.button} 
                        onClick={obj.disabled ? () => {} : () => this.liquidate(socket, obj[dev_trade.MARKET], obj[dev_trade.SIDE], obj[dev_trade.QUANTITY_1])} disabled={obj.disabled}
                    >
                        Liquider
                        <DeleteIcon className={this.props.classes.rightIcon} />
                    </Button>
        }
        return obj[head.id] !== undefined ? obj[head.id] : undef_default
    }

    label_for_table = (n,h,def) => this.label_to_print(n,h,def,this.props.socket !== undefined ? this.props.socket.socket : undefined);

    componentDidMount() {
        this.props.getAllTradesFixed();
        this.props.getAllCurrentTrades();
    }

    render() {
        const all_trades = this.props.trades.fixed !== undefined ? _.uniqBy(this.props.trades.fixed.concat(this.props.trades.current), x => x.timestamp1) : this.props.trades.current;

        const market = markets.find(x => x.short === "FDAX");


        if (all_trades !== undefined) {
            this.formattedTrades = all_trades.map(tradeAddProfit);
        }
        return (
            <div>
                <IndicatorPresentationnal market={market} classes={this.props.classes} indicators={this.props.indicators} />
                <TradePresentationnal headers={headers} rows={this.formattedTrades} order={dev_sort.DESC} orderBy={dev_trade.OPEN_DATE} label_to_print={this.label_for_table.bind(this)} />
            </div>
        );
    }
}

function mapStateToProps({ indicators, trades, socket }) {
    return { indicators, trades, socket };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllTradesFixed, getAllCurrentTrades, disableOneTrade }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeContainer));
