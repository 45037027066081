import React, { Component } from "react";
import StrategyContainer from '../../components/strategies/StrategyContainer';

   
class Strategy extends Component {
  render() {
    return (
      <div>
        <StrategyContainer />
      </div>
    );
  }
}

export default Strategy;
