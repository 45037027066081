import axios from 'axios';
import { IAConstants, IA_STATUS_URL } from '../../constants';

export const getIAStatusFromServer = () => {
    const request = axios.get(IA_STATUS_URL);
    return {
        type: IAConstants.GET_IA_STATUS_FROM_SERVER,
        payload: request
    }
}

export const changeIAStatus = (iaStatus) => {
    // Only changes the local save of iaStatus. The request is made before that.
    return {
        type: IAConstants.CHANGE_STATUS,
        payload: iaStatus
    }
}