import React, { Component } from "react";
import TradeContainer from '../../components/trades/tradeContainer';

class Trade extends Component {
    render() {
        return (
        <div>
            <TradeContainer />
        </div>
        );
    }
}

export default Trade;
