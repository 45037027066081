import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import PrivateLayout from '../layouts/privateLayout';
import PublicLayout  from '../layouts/publicLayout';

import privateRoutes from './privateRoutes';
import publicRoutes  from './publicRoutes';
import history from './history';

import Login from '../views/login/login';

class Template extends Component {
    render() {
        const socket = this.props.socket;
        return (
        <Router history={history}>
            <Switch>

                { _.map(publicRoutes, (route, key) => {
                    const { component, path } = route;
                    return (
                        <Route
                            exact
                            path={path}
                            key={key}
                            render={ (route) => <PublicLayout component={component} route={route} /> }
                        />
                    )
                }) }

                { _.map(privateRoutes, (route, key) => {
                    const { component, path, title, avatar, color } = route;
                    return (
                        <Route
                            exact
                            path={path}
                            key={key}
                            render={ (route) =>
                                socket.login_success !== undefined && socket.login_success === true ? (
                                <PrivateLayout component={component} route={route} title={title} avatar={avatar} color={color} />
                                ) : (
                                <PublicLayout component={Login} route={route} />
                                )
                            }
                        />
                    )
                }) }

            </Switch>
        </Router>
        );
    }
}

function mapStateToProps({socket}) {
    return {socket};
};

export default connect(mapStateToProps)(Template);