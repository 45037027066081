import { accountConstants } from '../../constants';

function compteReducer (state = [], action) {
    switch(action.type) {
        case accountConstants.GET_ACCOUNTS:
            return action.payload;
        
        default:
            return state;
    }
}

export default compteReducer;