import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import StaticAppBar from './static/staticAppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { company_name } from '../constants';

const styles = {
    card: {
      width: 407,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 100
    },
    media: {
      height: 116
    }
  };
  
  class PublicLayout extends Component {
    render() {
        const { classes, route } = this.props;
        const Component = this.props.component;
        return (
            <div className={classes.center}>
                <StaticAppBar />
                <Card className={classes.card}>
                <CardMedia  
                    className={classes.media}         
                    image="/assets/images/login_ia.jpg"
                    title={company_name}
                />
                <CardContent>
                    <Component route={route}/>
                </CardContent>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(PublicLayout);
