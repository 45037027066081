import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, connectWebSocket } from './loginAction';
import { routes_menu } from '../../constants';
import { getNewTrade, updateOneTrade, closeTrade } from '../trades/tradeAction';
import { addNotification } from '../notifications/notificationAction';
import { WEBSOCKET_URL } from '../../constants';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Mail from '@material-ui/icons/Mail';
import Lock from '@material-ui/icons/Lock';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import history from '../../routes/history';
import { used_language, label_connexion, wrong_mdp, no_mail, no_mdp } from '../../langues';

const styles = {
    icon: {
      marginTop: 20
    },
    textField: {
        width: 320
    },
    formControl: {
        marginBottom: 15
    }
 };


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            submitted: false,
            wrong: false,
            open: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    doIfLogged(pass) {
        // Setting up the websocket communication
        this.props.connectWebSocket(WEBSOCKET_URL);

        history.push(routes_menu[0]);

        return pass;
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true, wrong: false });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password, this.doIfLogged.bind(this), () => { return {login_success: false}});
            if (!this.props.socket.login_success) {
                this.setState({ wrong: true });
            }
        }

    }

    render() {
        const { username, password, submitted, wrong } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <form name="form" onSubmit={this.handleSubmit} >
                    <div>
                        { wrong && <Grid container direction="row" justify="center" alignItems="center" >
                                        <FormControl className={classes.formControl} error>
                                            <FormHelperText>{wrong_mdp[used_language]}</FormHelperText>
                                        </FormControl>
                                    </Grid>}
                    </div>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <Grid container spacing={8} alignItems="flex-start">
                            <Grid item className={classes.icon} >
                                <Mail color="disabled" fontSize="small" />
                            </Grid>
                            <Grid item>
                                <TextField className={classes.textField} name="username" value={username} onChange={this.handleChange} label="E-mail" />
                                {submitted && !username &&
                                    <FormHelperText id="component-helper-text">{no_mail[used_language]}</FormHelperText>
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <br/>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <Grid container spacing={8} alignItems="flex-start">
                        <Grid item className={classes.icon} >
                            <Lock color="disabled" fontSize="small" />
                        </Grid>
                        <Grid item>
                            <TextField className={classes.textField} type="password" name="password" value={password} onChange={this.handleChange} label="Mot de passe" />
                            {submitted && !password &&
                                <FormHelperText id="component-helper-text">{no_mdp[used_language]}</FormHelperText>
                            }
                        </Grid>
                        </Grid>
                    </div>
                    <br/>
                    <div className="form-group" >
                        <Grid container direction="row" justify="center" alignItems="center" >
                            <Button type="submit" variant="contained" color="primary" className="btn btn-primary">
                                { label_connexion[used_language] }
                            </Button>
                        </Grid>
                    </div>
                </form>
            </div>
        );
    }
}


function mapStateToProps({ socket }) {
    return { socket };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ login, connectWebSocket, getNewTrade, updateOneTrade, closeTrade, addNotification }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login)));