import React, { Component } from "react";
import StatContainer from '../../components/stats/statContainer';


class Stat extends Component {
  render() {
    return (
      <div>
          <StatContainer />
      </div>
    );
  }
}

export default Stat;
