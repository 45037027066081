import { websocketConstants } from '../../constants';
import round from 'round-to';


// cf tradeContainer comments for more precision about the structure of data
function indicatorReducer (state = [], action) {
    switch(action.type) {        

        case websocketConstants.BALANCE_UPDATE:

            let found = state.find(function(element) {
                return element.code === action.payload.code;
            });

            if (found) {
                return state.map(function(x) {
                    let tmpBuy = action.payload.code === x.code ? action.payload.balanceBuy : x.balanceBuy;
                    let tmpSell = action.payload.code === x.code ? action.payload.balanceSell : x.balanceSell;
                    x.balanceBuy = tmpBuy;
                    x.balanceSell = tmpSell;
                    return x;
                })
            } else {
                return [{code: action.payload.code, balanceBuy: action.payload.balanceBuy, balanceSell: action.payload.balanceSell, buy: 0, sell: 0, strenghtBuy: 0, strenghtSell: 0}, ...state];
            }  
        
        case websocketConstants.ACCURATE_UPDATE:
    
            let foundAccurate = state.find(function(element) {
                return element.code === action.payload.code;
            });

            if (foundAccurate) {
                return state.map(function(x) {
                    let tmpBuy = action.payload.code === x.code ? round(parseFloat(String(action.payload.buy).replace(',', '.')) * 100, 2) : x.buy;
                    let tmpSell = action.payload.code === x.code ? round(parseFloat(String(action.payload.sell).replace(',', '.')) * 100, 2) : x.sell;
                    x.buy = tmpBuy;
                    x.sell = tmpSell;
                    return x;
                })
            } else {
                return [{code: action.payload.code, balanceBuy: 0, balanceSell: 0, buy: round(parseFloat(String(action.payload.buy).replace(',', '.')) * 100, 2), sell: round(parseFloat(String(action.payload.sell).replace(',', '.')) * 100, 2), strenghtBuy: 0, strenghtSell: 0}, ...state];
            } 

        case websocketConstants.STRENGHT_UPDATE:
    
            let foundStrenght = state.find(function(element) {
                return element.code === action.payload.code;
            });

            if (foundStrenght) {
                return state.map(function(x) {
                    let tmpBuy = action.payload.code === x.code ? round(parseFloat(String(action.payload.strenghtBuy).replace(',', '.')), 1) : x.strenghtBuy;
                    let tmpSell = action.payload.code === x.code ? round(parseFloat(String(action.payload.strenghtSell).replace(',', '.')), 1) : x.strenghtSell;
                    x.strenghtBuy = tmpBuy;
                    x.strenghtSell = tmpSell;
                    return x;
                })
            } else {
                return [{code: action.payload.code, balanceBuy: 0, balanceSell: 0, buy: 0, sell: 0, strenghtBuy: round(parseFloat(String(action.payload.strenghtBuy).replace(',', '.')), 1), strenghtSell: round(parseFloat(String(action.payload.strenghtSell).replace(',', '.')), 1)}, ...state];
            } 

        default:
            return state;
    }
}

export default indicatorReducer;
