import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Eject from '@material-ui/icons/Eject';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import { ia_shutdown_button_labels, used_language, ia_waiting } from '../../langues';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    button: {
        marginTop: 10,
        marginRight: 10
    }
};
  
const theme = createMuiTheme({
    palette: {
        pr: {
            light: '#4dabf5',
            main: '#2980b9',
            dark: '#0276aa',
            contrastText: '#fff',
          },
        sec: red
    },
    typography: {
        useNextVariants: true
    }
});

IAShutdownButton.propTypes = {
    classes: PropTypes.object.isRequired,
    onclick: PropTypes.func.isRequired
};

function IAShutdownButton(props) {
    const { classes, active, received_info, onclick } = props;
    return (
        <div>
            <MuiThemeProvider theme={theme}>             
                {received_info === true ? 
                    <Button variant="contained" color={active ? 'secondary' : 'primary' } onClick={onclick} className={classes.button}>
                        {active ? <Eject /> : <PlayArrow /> }
                        {active ?  ia_shutdown_button_labels[used_language][0]  : ia_shutdown_button_labels[used_language][1]}
                    </Button>
                : 
                    <Button variant="contained" className={classes.button} disabled>
                        <PlayArrow />
                        {ia_waiting[used_language]}
                    </Button> 
                }
            </MuiThemeProvider>
        </div>
    );
}

export default withStyles(styles)(IAShutdownButton);